var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"countryCalendar"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"sectionTitle bef"},[_vm._v(_vm._s(_vm.propsData.title))]),_c('p',{staticClass:"smallText"},[_vm._v(" "+_vm._s(_vm.propsData.subtitle)+" ")]),_c('div',{staticClass:"timeWrapper"},_vm._l((_vm.propsData.items),function(season,idx){return _c('div',{key:idx,staticClass:"col"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.propsData.seasons[idx]))]),_vm._l((season),function(month,idx){return _c('div',{key:idx,staticClass:"month"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(month.name))]),_c('ul',_vm._l((month.list),function(item,idx){return _c('li',{key:idx},[_c('router-link',{attrs:{"to":{
                  name: 'CatalogChild',
                  params: {
                    month: month.slug,
                    slug: item.slug,
                    locale: _vm.$i18n.locale == 'en' ? null : _vm.$i18n.locale,
                  }
                }}},[_c('i',{staticClass:"icon",class:item.image}),_c('span',[_vm._v(_vm._s(item.name))])])],1)}),0)])})],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }