<template>
    <div class="galleryViewModal" @click="$emit('close')">
        <span class="icon ic-arrow-left prev" @click="onPrev"></span>
        <span class="icon ic-arrow-right next" @click="onNext"></span>
        <span class="icon ic-close close" @click.stop="$emit('close')"></span>
        <Flickity ref="flickity" class="galSlider" :options="sliderOptions" >
            <div class="slide" v-for="(item, index) in compdata" :key="index" >
                <img :src="path(item.image)" :alt="path(item.image)" @load="refreshSlider('flickity')" />
            </div>
        </Flickity>
    </div>
</template>

<script>
import Flickity from "vue-flickity";
export default {
    props: ['compdata', 'curIndex'],
    components:{
        Flickity
    },
    data() {
        return {
            sliderOptions: {
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                imagesLoaded: true
            },
        }
    },
    methods: {
        onNext() {
            this.$refs.flickity.next();
        },
        onPrev() {
            this.$refs.flickity.previous();
        },
    },
    mounted () {
        this.$refs.flickity.select(this.curIndex-1, false, true);
    },
};
</script>

<style lang="sass" scoped>
.galleryViewModal
    background: rgba(0, 0, 0, 0.8)
    position: fixed
    z-index: 100
    top: 0
    bottom: 0
    right: 0
    left: 0
    .ic-arrow-left,
    .ic-arrow-right
        position: absolute
        z-index: 5
        top: 50%
        transform: translate(0, -50%)
        width: 80px
        height: 60px
        display: flex
        justify-content: center
        align-items: center
        background: #f2f2f2
        font-size: 15px
        cursor: pointer
        @media (max-width: 575px)
            display: none
    .ic-arrow-left
        left: 30px
    .ic-arrow-right
        right: 30px
    .close
        width: 46px
        height: 46px
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        top: 9px
        right: 9px
        font-size: 12px
        color: #fff
        z-index: 50
        cursor: pointer
    .galSlider
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        .slide
            width: 100vw
            height: 100vh
            display: flex
            justify-content: center
            align-items: center
            img
                max-width: calc(100% - 250px)
                max-height: calc(100% - 150px)
                @media (max-width: 575px)
                    max-width: 100vw
                    max-height: 100vh
.prev, .next
    width: 50px
    height: 50px
    display: flex
    justify-content: center
    align-items: center
    border: 1px solid red
    color: red
    cursor: pointer
    transition: .3s
    &:hover
        color: #fff
        border-color: red
        background-color: red
</style>
