<template>
  <div class="flickityWrapper">
    <div class="flickityInner container">
      <flickity ref="mainSlider" class="mainSlider" :options="mainSettings">
        <div
          class="carousel-cell"
          v-for="(slide, idx) in gelleryListSort"
          :key="idx"
          @click="openLightbox(idx)"
        >
          <img
            @load="loaded"
            :src="path(slide.image)"
            :alt="path(slide.image)"
          />
        </div>
      </flickity>
      <div class="navSliderWrapper">
        <flickity ref="navSlider" class="navSlider" :options="navSettings">
          <div
            class="carousel-cell"
            v-for="(item, idx) in gelleryListSort"
            :key="idx"
            @click="getSlideId(idx)"
          >
            <div class="imgWarpper">
              <img @load="loaded" :src="path(item.image)" alt="" />
            </div>
          </div>
        </flickity>
        <button class="sliderBtn prevBtn" @click="previous()">
          <i class="ic-arrow-drop"></i>
        </button>
        <button class="sliderBtn nextBtn" @click="next()">
          <i class="ic-arrow-drop"></i>
        </button>
      </div>
      <app-carousell-lightbox
        v-if="isLightboxOpen && false"
        @close="closeLightbox"
      ></app-carousell-lightbox>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import AppCarousellLightbox from "./app-carousell-lightbox.vue";
export default {
  components: {
    Flickity,
    AppCarousellLightbox
  },
  props: {
    galleryData: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      isLightboxOpen: false,
      currentNavSlide: 0,
      mainSettings: {
        prevNextButtons: false,
        pageDots: false
      },
      navSettings: {
        prevNextButtons: false,
        contain: true,
        pageDots: false
      }
    };
  },
  computed: {
    gelleryListSort() {
      const temp = [];
      for (const key in this.galleryData.list) {
        if (Object.hasOwnProperty.call(this.galleryData.list, key)) {
          const element = this.galleryData.list[key];
          temp.push({
            image: element.image,
            image_title: element.image_title,
            sort: element.sort
          });
        }
      }
      console.log(temp.sort((a, b) => +a.sort - +b.sort));
      return temp;
    }
  },
  methods: {
    openLightbox() {
      this.isLightboxOpen = !this.isLightboxOpen;
    },
    closeLightbox() {
      this.isLightboxOpen = false;
    },
    getSlideId(id) {
      console.log(id - 1);
      this.$refs.navSlider.select(id - 1);
      this.$refs.mainSlider.select(id - 1);
    },
    loaded() {
      this.$refs.mainSlider.resize();
    },
    next() {
      this.$refs.navSlider.next();
      this.$refs.mainSlider.select(this.currentNavSlide);
    },
    previous() {
      this.$refs.navSlider.previous();
      this.$refs.mainSlider.select(this.currentNavSlide);
    }
  },
  mounted() {
    this.$refs.navSlider.on("change", (idx) => {
      this.currentNavSlide = idx;
    });
    this.$refs.mainSlider.on("change", (idx) => {
      this.$refs.navSlider.select(idx);
    });
  }
};
</script>

<style lang="sass">
.carousel-cell, .slide
    img
        transition: 0.3s ease
</style>
<style lang="sass" scoped>
.flickityWrapper
    overflow-x: hidden
.navSliderWrapper
    position: relative
    @media (max-width: 768px)
        width: 90%
        margin: 0 auto
    .sliderBtn
        position: absolute
        top: 50%
        transform: translateY(-50%)
        background-color: unset
        border: none
        i
            color: $c-btn
            font-size: 27px
    .prevBtn
        left: 40px
        transform: translateY(-50%) rotate(180deg)
        @media (max-width: 768px)
            left: -35px
        @media (max-width: 576px)
            left: -25px
    .nextBtn
        right: 40px
        @media (max-width: 768px)
            right: -35px
        @media (max-width: 576px)
            right: -25px
</style>
