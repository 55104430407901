<template>
    <ul class="breadcrumbs">
        <li v-for="(item, idx) in breadcrumbs" :key="idx">
            <a :href="item.url">{{ item.title || item.name }}</a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            breadcrumbs: {
                type: [Array, Object],
            },
        },
    }
</script>

<style lang="sass" scoped>
.breadcrumbs
    display: flex
    align-items: center
    margin-bottom: 29px
    @media (max-width: 576px)
        // overflow-x: scroll
        flex-wrap: wrap
    li
        font-weight: 300
        font-size: 14px
        line-height: 140%
        text-align: center
        color: #CACACA
        position: relative
        @media (max-width: 576px)
            white-space: nowrap
            margin-bottom: 10px
        &:last-of-type
            color: $c-btn
            pointer-events: none
        &:not(:last-of-type)
            margin-right: 26px
            &::after
                content: '>'
                position: absolute
                top: 55%
                transform: translateY(-50%)
                right: -16px
                line-height: 140%
</style>
