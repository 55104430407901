<template>
  <div class="country" v-if="!isUiLocked">
    <div class="container textContainer">
      <app-breadcrumbs :breadcrumbs="breadcrumbsList"></app-breadcrumbs>
      <the-main-text
        :title="countryInfo.name"
        :descr="countryInfo.description"
      ></the-main-text>
      <app-tabs class="mt" :tabList="tabsList"></app-tabs>
    </div>
    <app-main-carousell
      v-if="galleryData.content.list"
      :galleryData="galleryData.content"
    ></app-main-carousell>
    <div class="container infoZone" v-if="constructorData">
      <anker-list
        :ankerList="ankerList"
        :title="$t('countryAnkerListTitle')"
        @click="onAnkerClick"
      ></anker-list>
      <div class="contentZone" ref="contentZone">
        <components
          v-for="(item, idx) in constructorFiltered"
          :is="item.component"
          :key="idx"
          :propsData="item.content"
          @ankerListCreated="setAnkerList"
        ></components>
      </div>
    </div>
    <template v-if="widgetList">
      <component
        v-for="(item, idx) in widgetList"
        :key="idx"
        :is="item.component"
        :propsData="item.content"
      >
      </component>
    </template>

    <app-see-also
      :morfTitle="countryInfo.morf"
      :tabList="tabsList"
    ></app-see-also>
  </div>
</template>

<script>
import AppTabs from "../components/common/app-tabs.vue";
//
import AppCountryCalendar from "../components/country/app-country-calendar.vue";
import AppSeeAlso from "../components/country/app-see-also.vue";
import AppListing from "../components/text-components/app-listing.vue";
import SimpleText from "../components/text-components/app-simple-text.vue";
import { mapActions, mapGetters } from "vuex";
import AppMainCarousell from "../components/common/app-MainCarousell.vue";

import accordionMixin from "@/textAccordion";
import TheMainText from "../components/common/the-main-text.vue";
import AnkerList from "../components/navigation/anker-list.vue";
import AppBreadcrumbs from "../components/common/app-breadcrumbs.vue";

export default {
  components: {
    AppTabs,
    calendar: AppCountryCalendar,
    AppSeeAlso,
    AppListing,
    SimpleText,
    AppMainCarousell,
    TheMainText,
    AnkerList,
    AppBreadcrumbs
  },
  mixins: [accordionMixin],
  data() {
    return {
      countryInfo: null,
      constructorData: null,
      tabsList: null,
      widgetList: null,
      ankerList: null,
      breadcrumbsList: null
    };
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"]),
    setAnkerList(ankerList) {
      this.ankerList = ankerList;
    }
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
    galleryData() {
      return this.constructorData[0];
    },
    constructorFiltered() {
      const temp = [];
      this.constructorData.forEach((el) => {
        if (el.top != 1) {
          temp.push(el);
        }
      });
      return temp;
    }
  },
  async created() {
    this.lockUi();
    const res = await this.axios.post("/api/country/get-by-slug", {
      slug: this.$route.params.countryName,
      lang: this.currentLang
    });
    this.tabsList = res.data.data.tabs;
    this.countryInfo = res.data.data.translate;
    this.constructorData = res.data.data.constructor;
    this.widgetList = res.data.data.widgets;
    this.breadcrumbsList = res.data.data.breadcrumbs;
    document.title =
      res.data.data.translate.meta_title ||
      res.data.data.translate.name ||
      "Dream Travel";
    setTimeout(() => {
      this.initToggleSlideBtn(this.$refs.contentZone, false); // from mixin
    }, 500);
    this.unlockUi();
  },
//   mounted() {
//     window.addEventListener("scroll", () => {
//       let scrollDistance = window.scrollY;

//     //   console.log(scrollDistance);

//       if (window.innerWidth > 1366) {
//           this.ankerList.forEach(item => {
//               document.querySelectorAll(`[href="${item.link}"]`).forEach(el => {
//                       console.log(el);
//                       el.classList.remove('active')
//                   })
//               if ( scrollDistance >= item.cord ) {
//                 //   console.log(document.querySelector(`[href="${item.link}"]`));

//                   document.querySelector(`[href="${item.link}"]`).classList.add('active')
//               }
//           })
//         // 		document.querySelectorAll('.anker').forEach((el, i) => {
//         // 			if (el.offsetTop - document.querySelector('.aside').clientHeight <= scrollDistance) {
//         // 				document.querySelectorAll('.aside ul li').forEach((el) => {
//         // 					if (el.classList.contains('active')) {
//         // 						el.classList.remove('active');
//         // 					}
//         // 				});
//         // 				document.querySelectorAll('.aside ul li')[i].classList.add('active');
//         // 			}
//         // 		});
//       }
//     });
//   }
};
</script>

<style lang="sass" scoped>
.descr
    font-weight: 300
    font-size: 17px
    line-height: 150%
    text-align: center
    color: #FFFFFF
    max-width: 586px
    margin: 0 auto
    margin-bottom: 70px
</style>
